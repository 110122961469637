<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>部门：</span>
              <DeptCascader
                :placeholder="'请选择部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </div>
            <div class="search_center">
              <span>姓名：</span>
              <el-input
                v-model="parameter.userName"
                @change="dataUpdate"
                class="ipt_width"
                placeholder="请输入姓名"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span> 单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>

            <div class="search_center">
              <span> 项目名称：</span>
              <el-select
                v-model="parameter.projectId"
                clearable
                filterable
                placeholder="请输入或选择项目名称"
                class="ipt_width project_width"
                @change="dataUpdate"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">报销流程</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.approvalType" class="fullScreenMainHeader">
            <el-tab-pane name="NOT_PAYED" label="待报销"></el-tab-pane>
            <el-tab-pane name="ALREADY_PAYED" label="已报销"></el-tab-pane>
          </el-tabs>

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              width="160"
              prop="expenseNumber"
              label="单据编号"
            ></el-table-column>
            <el-table-column
              align="center"
              sortable
              width="100"
              prop="createName"
              label="姓名"
            ></el-table-column>
            <el-table-column
              align="center"
              sortable
              width="110"
              prop="deptName"
              label="部门"
            ></el-table-column>
            <!-- <el-table-column align="center" prop="postName" label="岗位"></el-table-column> -->
            <el-table-column
              align="center"
              prop="projectName"
              sortable
              min-width="320"
              :show-overflow-tooltip="false"
              label="项目名称"
            ></el-table-column>
            <el-table-column
              prop="priority"
              :show-overflow-tooltip="false"
              min-width="120"
              label="是否优先发放"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.priority == 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column align="center" sortable width="110" prop="sumCost" label="报销费用">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template></el-table-column
            >
            <el-table-column align="center" width="110" sortable prop="taskDate" label="申请日期">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="expenseMode"
              label="报销方式"
            >
              <template slot-scope="scope">
                {{ scope.row.expenseMode | dict(expenseModeList) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="applyExpenseType"
              label="付款方式"
            >
              <template slot-scope="scope">
                {{ scope.row.applyExpenseType | dict(applyExpenseType) }}
              </template></el-table-column
            >
            <el-table-column align="center" width="150" sortable prop="statusName" label="流程进度">
            </el-table-column>

            <el-table-column prop="title" align="center" fixed="right" width="70" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  v-if="parameter.approvalType == 'NOT_PAYED'"
                  class="text_Grant_Bgc"
                  @click="detail(scope.row)"
                  >发放</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  v-else
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  >详情</el-button
                >
                <!-- <el-button
                  type="text"
                  size="small"
                  class="text_Grant_Bgc"
                  @click.stop="affirmCost(scope.row.id)"
                  v-if="parameter.approvalType == 'NOT_PAYED'"
                  >确认报销</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>

          <!-- <EditDialog :isShow.sync="dialogFormVisible" @submit="submit">
            <div slot="title" class="title">确认报销</div>
            <div slot="content" class="content">
              <el-form :model="dg_form" label-width="100px">
                <el-form-item label="附件" prop="remark">
                  <div class="iconCost">
                    <Upload :type="type" :fields="fields" @upload="upload"></Upload>
                  </div>

                  <div
                    v-for="(n, index) in dg_form.affirmCostFileList"
                    :key="index"
                    style="margin-top:5px"
                  >
                    <a
                      class="fj-text"
                      target="_blank"
                      style="text-decoration:revert;color:#409eff;padding-right:8px;"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>

                    <i @click="del(index)" class="iconfont iconerror iconCost"></i>
                  </div>
                </el-form-item>

                <el-form-item label="备注" prop="remark">
                  <el-input v-model="dg_form.remark" type="textarea"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </EditDialog> -->
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="项目报销流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/项目报销流程图.png" alt="项目报销流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit :isShow.sync="showEdit" :getTitle="getTitle" :options="form"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    Edit: () => import('../projectCost/Edit.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        userName: null,
        deptId: null,
        expenseNumber: null,
        projectId: null,
        pageNow: 1,
        pageSize: 50,
        total: 0,
        approvalType: 'NOT_PAYED',
      },
      pageSize: 0,
      tableData: [],
      loading: false,
      showEditDialog: false,
      // dialogFormVisible: false,
      // dg_form: {
      //   affirmCostFileList: [],
      //   remark: "",
      // },
      // //文件上传
      // filepath: process.env.VUE_APP_FILEPATH,
      // fields: {
      //   name: "fileName",
      //   path: "filePath",
      //   type: "type",
      // },
      // type: "XMBX",
      showEdit: false,
      form: {},
      projectList: [], // 项目数据
      deptList: [], // 部门 列表
      drawer: false,
      createdDate: [],
      getTitle: '',
      expenseModeList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      applyExpenseType: [], //付款方式字典
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.getProjectList() /** 加载 "项目" 数据 **/
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.$api.dict.listSysDictData('EXPENSE_MODE', true).then(res => {
      this.expenseModeList = res.data
    })
    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  watch: {
    'parameter.approvalType': {
      // immediate:true,//初始化立即执行
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    getData() {
      this.loading = true

      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }
      this.$api.projectCost
        .searchTask(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
            this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    dataUpdate() {
      this.$forceUpdate()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // submit() {
    //   this.loading = true;
    //   this.$api.project
    //     .affirmApply(this.dg_form)
    //     .then((res) => {
    //       this.loading = false;
    //       this.$message({
    //         type: "success",
    //         message: "成功!",
    //       });
    //       this.getData();
    //       this.dialogFormVisible = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.loading = false;
    //     });
    // },
    // del(index) {
    //   this.dg_form.affirmCostFileList.forEach((element, i) => {
    //     if (i == index) {
    //       this.dg_form.affirmCostFileList.splice(index, 1);
    //     }
    //   });
    // },
    // affirmCost(id) {
    //   (this.dg_form = {
    //     affirmCostFileList: [],
    //     remark: "",
    //   }),
    //     (this.dg_form.id = id);
    //   this.dialogFormVisible = true;
    // },
    // upload(r) {
    //   r[0].type = "项目报销附件";
    //   this.dg_form.affirmCostFileList.push(...r);
    // },
    detail(row) {
      this.getTitle = '支付报销单详情'
      this.form = {
        list_s: 3,
        ...row,
      }
      this.form.passText = '发放'
      this.showEdit = true
    },
    re() {
      this.form = {}
      this.getData()
      this.showEdit = false
    },
    /** 加载 "项目" 数据 **/
    getProjectList() {
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.userName = null
      this.parameter.projectId = null
      this.parameter.expenseNumber = null
      this.parameter.deptId = null
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.createdDate = []
      this.parameter.pageNow = 1
      this.getData() /** 加载  数据 **/
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data ? res.data : []
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
